import { AddressModel } from "../AddressModel";
import BankResponseModel, { BankIbanModel } from "./BankResponseModel";

export default class BankRequestModel {
  constructor(
    public name = "",
    public code = "",
    public address: AddressModel = new AddressModel(),
    public swift = "",
    public ibans: Array<BankIbanModel> = [],
  ) {}

  static of(model: BankResponseModel | BankRequestModel): BankRequestModel {
    return new BankRequestModel(
      model.name,
      model.code,
      model.address,
      model.swift,
      model.ibans
    );
  }
}
