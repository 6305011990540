
























































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import SidebarForm from "../SidebarForm.vue";

import BankRequestModel from "@/accounting-portal/models/banks/BankRequestModel";
import BankResponseModel, {
  BankIbanModel,
} from "@/accounting-portal/models/banks/BankResponseModel";
import { CounterpartyCurrencyCode } from "@/accounting-portal/models/counterparties/CounterpartyCurrencyCode";
import ValidUtil from "@/shared/utils/ValidUtil";
import ValidationModel, {
  ValidationErrorCode,
} from "@/shared/models/ValidationModel";

@Component({
  components: {
    SidebarForm,
  },
})
export default class BankForm extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop() editingItem!: BankResponseModel | null;

  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly CURRENCY_CODES = Object.values(CounterpartyCurrencyCode);
  localValue = new BankRequestModel();

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    if (!value) {
      this.$store.commit("clearValidations");
      this.localValue = new BankRequestModel();
    }

    this.$emit("input", value);
  }

  get title(): string {
    return this.editingItem
      ? this.$lang("accountingPortal.banks.bankDetailsTitle")
      : this.$lang("accountingPortal.banks.newBankTitle");
  }

  get saving(): boolean {
    return this.$store.state.bankStore.isBankSaving;
  }

  get isBankNameUnique(): boolean {
    return !this.validations.some(
      ({ field, errorCode }) =>
        field === "name" && errorCode === ValidationErrorCode.NOT_UNIQUE
    );
  }

  get isBankCodeUnique(): boolean {
    return !this.validations.some(
      ({ field, errorCode }) =>
        field === "code" && errorCode === ValidationErrorCode.NOT_UNIQUE
    );
  }

  get nameErrorMessages(): Array<string> {
    if (!this.isBankNameUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }

    return [];
  }

  get codeErrorMessages(): Array<string> {
    if (!this.isBankCodeUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }

    return [];
  }

  get validations(): Array<ValidationModel> {
    return this.$store.state.validationStore.validations;
  }

  @Watch("editingItem", { deep: true })
  watchEditingItem(value: BankResponseModel | null) {
    if (!value) {
      return;
    }

    this.localValue = BankRequestModel.of(cloneDeep(value));
  }

  @Watch("localValue.name", { deep: true })
  watchLocalValueName() {
    if (this.nameErrorMessages.length) {
      this.$store.commit("clearValidationByField", "name");
    }
  }

  @Watch("localValue.code", { deep: true })
  watchLocalValueCode() {
    if (this.codeErrorMessages.length) {
      this.$store.commit("clearValidationByField", "code");
    }
  }

  handleAddingIban() {
    this.localValue.ibans.push(new BankIbanModel());
  }

  handleDeleteIbanByIndex(index: number) {
    this.localValue.ibans.splice(index, 1);
  }

  async handleSave(closeDialog: () => void) {
    await this.$store.dispatch("validateBank", {
      id: this.editingItem ? this.editingItem.id : undefined,
      ...this.localValue,
    });

    if (this.editingItem) {
      await this.$store.dispatch("updateBank", {
        id: this.editingItem.id,
        payload: this.localValue,
      });
    } else {
      await this.$store.dispatch("createBank", this.localValue);
    }

    closeDialog();
  }
}
